<template>
  <th-page-wrapper>
    <th-tab-page-wrapper :headers="headers">
      <!-- key in router-view forces the used component to be re-created also on query params change on the route -->
      <router-view :key="$route.fullPath" :resources="resources" />
    </th-tab-page-wrapper>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { getNavigation } from './navigation'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  data() {
    return {
      resources: {}
    }
  },
  computed: {
    headers() {
      const headers = getNavigation({ featureConfig: this.featureConfig })
      return headers
        .filter((item) => {
          return (
            item.enabled === true &&
            this.$checkPermissions({ scopes: item.scopes })
          )
        })
        .map((item) => {
          return {
            label: this.$t(item.key),
            key: item.key,
            value: item.value
          }
        })
    }
  },
  async mounted() {
    this.resources = await this.$resourceFetch('expenseAccounts', {
      resource: 'accounts',
      handler: () =>
        th.accounts().getAll({ query: { type: 'revenue', deleted: false } })
    })
  }
}
</script>

<style scoped></style>
